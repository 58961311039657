<template>
	<div>
		<section class="section is-main-section pb-0 is-align-items-center">
			<div class="box table-filter">
				<div class="is-flex is-align-items-center">
					<div class="table-filter__item">
						<b-field label="Tarih">
							<finance-datepicker
								:lang="datepicker.datePickerLang"
								v-mask="'##.##.####-##.##.####'"
								v-model="form.date"
								type="date"
								format="DD.MM.YYYY" />
						</b-field>
					</div>
					<div class="table-filter__item">
						<b-field label="Site">
							<b-select size="is-small" v-model="form.siteId">
								<option :value="null">-</option>
								<option
									:value="item.uuid"
									v-for="item in sites"
									:key="item.uuid">
									{{ item.name }}
								</option>
							</b-select>
						</b-field>
					</div>
					<div class="table-filter__item">
						<b-field label="Method">
							<b-select size="is-small" v-model="form.method">
								<option :value="null">-</option>
								<option
									:value="key"
									v-for="(item, key) in enums.depositTypes"
									:key="item">
									{{ item }}
								</option>
							</b-select>
						</b-field>
					</div>
					<div
						class="is-flex is-flex-grow-1 is-align-self-flex-end is-justify-content-end">
						<b-button
							icon-left="sync"
							size="is-small"
							type="is-info"
							@click="getDashboardDetail"
							>Yenile</b-button
						>
					</div>
				</div>
			</div>
		</section>
		<section class="section is-main-section">
			<div class="tile is-ancestor is-flex-wrap-wrap">
				<div
					class="tile is-parent is-3"
					v-for="(item, key) in totals"
					:key="key">
					<article class="tile is-child">
						<card-component
							:title="`${enums.depositTypes[item.method]} - ${
								item.account
							}`"
							:title-class="`${
								item.status === 'ENABLED'
									? 'has-background-success has-text-white'
									: 'has-background-danger has-text-white'
							}`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title">
									<growing-number
										:value="parseFloat(item.total)"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<!--<div class="subtitle is-7 mb-0">
									Günlük Limit: ₺{{ item.dailyAmountLimit }}
								</div>-->
								<div class="subtitle is-7 mb-0">
									Min. Yatırım Limiti: ₺{{
										item.minimumLimit
									}}
								</div>
								<div class="subtitle is-7 mb-0">
									Max. Yatırım Limiti: ₺{{
										item.maximumLimit
									}}
								</div>
							</div>
						</card-component>
					</article>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import Tiles from "@/components/Tiles"
	import CardWidget from "@/components/CardWidget"
	import CardComponent from "@/components/CardComponent"
	import ClientsTableSample from "@/components/ClientsTableSample"
	import { ReportService as Service, SiteService } from "@/services"
	import { startOfDay, subMinutes } from "date-fns"
	import GrowingNumber from "@/components/GrowingNumber.vue"

	export default {
		name: "Home",
		components: {
			CardComponent,
			CardWidget,
			Tiles,
			ClientsTableSample,
			GrowingNumber,
		},
		data() {
			return {
				totals: [],
				subMinutes,
				startOfDay,
				sites: [],
				form: {
					method: null,
					siteId: null,
					date: new Date(),
				},
			}
		},
		mounted() {
			this.getDashboardDetail()
			this.getSites()
		},
		methods: {
			getDashboardDetail() {
				this.totals = []
				const startDate = this.getIstanbulDate(this.form.date)
				const endDate = this.getIstanbulDate(this.form.date, false)
				Service.dailyAccount({
					siteId: this.form.siteId,
					dates: [startDate, endDate],
					method: this.form.method,
				}).then((res) => (this.totals = res.data))
			},
			getSites() {
				SiteService.all({ status: 1 }).then(
					(res) => (this.sites = res.data)
				)
			},
		},
		watch: {
			form: {
				handler: function () {
					this.getDashboardDetail()
				},
				deep: true,
			},
		},
	}
</script>
